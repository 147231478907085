import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as filters from './filters' // global filters
// 全局引入axios
import http from 'axios'
Vue.prototype.$http = http
// 引入cookie
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

// 引入所有element组件--！
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入mock
import '../api/mock.js'


Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i;
  }
  return -1;
};

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
