// import { USER_LOGIN , INI_LOGIN} from './mutations-types.js'
export default {
    state: {
    },
    getters: {
    },
    mutations: {
		// 获取登陆状态
		// [USER_LOGIN](state,user){
		// 	state.success = user;
		// 	localStorage.setItem('SUCCESS',JSON.stringify(user))
		// },
		// 读取登陆状态
		// [INI_LOGIN](state){
		// 	let Success = JSON.parse(localStorage.getItem('SUCCESS')) 
		// 	if(Success){
		// 		state.success = Success
		// 		if(Success == true){
		// 			console.log('登陆状态已登录')
		// 		}else{
		// 			console.log('登陆状态未登录')
		// 		}
		// 	}
		// },
		// 退出登录
		// Outlogin(state) {
		// 	localStorage.removeItem('USERS');
		// }
    },
    actions: {

    },
    modules: {

    }
}