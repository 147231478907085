import Vue from 'vue'
import VueRouter from 'vue-router'
// import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    redirect: 'home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home')
      },
      {
        path: '/juLiangAccount',
        name: 'juLiangAccount',
        component: () => import('../views/account/juLiangAccount')
      },
      {
        path: '/taoBaoGenerate',
        name: 'taoBaoGenerate',
        component: () => import('../views/urlGenerate/taoBaoGenerate')
      },
      {
        path: '/GoodReporting',
        name: 'GoodReporting',
        component: () => import('../views/urlGenerate/GoodReporting')
      },
      {
        path: '/pddOrder',
        name: 'pddOrder',
        component: () => import('../views/order/pddOrder')
      },
      {
        path: '/juLiangRealtimeData20',
        name: 'juLiangRealtimeData20',
        component: () => import('../views/realtimeData/juLiangRealtimeData20')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
